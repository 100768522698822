<template>
  <div class="userss">
    <div class="users">
      <div class="infoDetails">
        <div class="info">
          <img class="userImg" :src="detail.img" alt="">
          <div class="nickName">
            <div class="name">{{ detail.nickName }}</div>
            <div class="sex">{{ detail.sex }} <span style="margin: 0 2px;" v-if="detail.sex && detail.autograph">丨</span>
              {{ detail.autograph }}
            </div>
          </div>
        </div>
      </div>
      <!-- <img class="tabImg" src="@/assets/img/homeSeventh/myDataBg.png" /> -->
      <div class="conmain">
        <div class="nav_center">
          <!-- /* 用户信息 */ -->
          <!-- 导航菜单 -->
          <div class="mymenu">
            <div v-for="(item, index) in list" :key="index" class="mymenu-item" :class="[tabId == item.url ? 'on' : '']"
              @click.stop="tabChange(item)">
              <img class="infobac" src="@/assets/img/partner/infobac.png" alt="">
              <i class="iconfont" :class="[item.icon]" /><span>{{
                item.title
              }}</span>
            </div>
          </div>
        </div>

        <div class="content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { getUserCourseStatistics } from '@/api/user'
export default {
  components: {},

  data() {
    return {
      m: '',
      tel: '',
      uid: '',
      userId: '',
      list: [
        {
          id: 2,
          title: '个人资料',
          url: '/partnerPage/my/myInfo',
          icon: 'icon-bianjigerenziliao'
        },
        {
          id: 3,
          title: '我的订单',
          url: '/partnerPage/my/mineOrder',
          icon: 'icon-a-Group1530'
        },
        {
          id: 4,
          title: '优惠券',
          url: '/partnerPage/my/mineCoupon',
          icon: 'icon-youhuiquan1'
        },
        {
          id: 6,
          title: '兑换券',
          url: '/partnerPage/my/mineExchange',
          icon: 'icon-youhuiquan1'
        },
        {
          id: 10,
          title: '我的提问',
          url: '/partnerPage/my/myAskQuestions',
          icon: 'icon-youhuiquan1'
        },
        {
          id: 9,
          title: '学习卡',
          url: '/partnerPage/my/myCard',
          icon: 'icon-xuexika'
        },
        {
          id: 7,
          title: '意见反馈',
          url: '/partnerPage/my/feedback',
          icon: 'icon-yijianfankui1'
        },
        {
          id: 8,
          title: '账户设置',
          url: '/partnerPage/my/accountSetting',
          icon: 'icon-zhanghushezhi'
        },
        {
          id: 5,
          title: '我的消息',
          url: '/partnerPage/my/mineNews',
          icon: 'icon-a-Group1533'
        },
        {
          id: 10,
          title: '我的收藏',
          url: '/partnerPage/my/collect',
          icon: 'icon-shoucangxing'
        }
      ],
      detail: {
        img: '',
        nickName: ''
      },
      tabId: 1,
      chong: {}
    }
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      if (oldVal.path == '/login' || newVal.path == '/login') {
        this.userId = localStorage.getItem('userId')
      }
    }
  },
  created() {
    this.userId = localStorage.getItem('userId')
    if (!this.userId) {
      Vue.prototype.goLoginView('mustBack')
      return
    }
    this.tabId = this.$route.path

    this.detail = getInfo()
    // 个人中心 顶部数据
    getUserCourseStatistics(this.userId).then((res) => {
      this.chong = res.msg
    })

    // 滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        const top = $(window).scrollTop()
        if (top >= 290) {
          $('.userCentLeft').addClass('fixed-card')
          $('.zhicheng').removeClass('dis-none')
        } else {
          $('.userCentLeft').removeClass('fixed-card')
          $('.zhicheng').addClass('dis-none')
        }
      })
    })
  },
  methods: {
    tabChange(val) {
      if (this.tabId == val.url) {
        return
      }
      this.titles = val.title
      this.tabId = val.url
      this.$router.replace(val.url)
    }
  }
}
</script>
<style lang="less" scoped>
.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}

.el-input.el-input__inner {
  width: 100px;
}

.userss {

  width: 100%;
  height: 100%;

  background: linear-gradient(180deg, #E2EDFF 0%, #F7FAFE 100%);
}

.users {
  background-image: url('~@/assets/img/partner/info.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;


  .infoDetails {
    width: 1200px !important;
    margin: 0 auto;
    height: 157px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .info {
      display: flex;
      align-items: start;
      justify-content: center;

      .userImg {
        width: 75px;
        height: 75px;
        opacity: 1;
        border: 2px solid #ffffff;
        border-radius: 50%;
        margin-right: 20px;
        box-shadow: 0 3px 6px 1px rgba(255, 255, 255, 0.06);
      }

      .nickName {
        height: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        // text-align: center;
        margin-top: 10px;

        .sex {
          margin-top: 26px;
          height: 14px;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 14px;
        }
      }
    }
  }

  .tabImg {
    width: 100%;
    height: 80px;
  }

  .conmain {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    // margin-top: 20px;
    justify-content: space-between;
  }

  .nav_center {
    width: 146px;
    height: 500px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    // padding: 22px 16px;
    margin-right: 20px;
    flex-shrink: 0;
    /* 用户信息 */

    /* 导航菜单 */
    .mymenu {

      // margin-top: 16px;
      .mymenu-item {
        position: relative;
        width: 148px;
        height: 50px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        background-size: 156px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        text-align: center;
        display: flex;
        padding-left: 24px;
        cursor: pointer;

        .iconfont {
          z-index: 999;
          flex-shrink: 0;
          font-size: 16px;
          margin-right: 16px;
          color: #4a6af0;
        }

        span {
          z-index: 999;
        }

        .infobac {
          position: absolute;
          display: none;
          top: 0;
          left: 0;
          width: 156px;
          height: 50px;
        }
      }

      .on {
        // background-image: url("~@/assets/img/partner/infobac.png");
        color: #ffffff;

        .iconfont {
          color: #ffffff;
        }

        .infobac {
          display: block;
        }
      }
    }
  }
}

.zhicheng {
  width: 180px;
  height: 100%;
  margin-right: 20px;
  flex-shrink: 0;
}

.ccc {
  color: #1061ff;
}

.content {
  width: 1084px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 0 20px;
}
</style>
